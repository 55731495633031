// react
import { useContext } from 'react'
// @mui
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Share } from '@mui/icons-material'
// components
import NativeImage from '~/components/NativeImage'
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
// utils
import { formatSimpleNumber, formatOrFallback } from '~/libs/numberFormatter'
// utils
import fallbackImage from '~/libs/fallbackImage'

// page specific
import { CatalogPageContext } from '../context'

// ----------------------------------------------------------------------

const BackgroundImage = styled(NativeImage)({
  width: '100%',
  height: '24ch',
  borderRadius: '0 0 1rem 1rem',
  backgroundColor: 'divider',
  objectFit: 'cover',
  objectPosition: 'center'
})

const ProfileImageWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-5ch',
  paddingLeft: theme.spacing(3),
  zIndex: 10
}))

const ProfileImage = styled(NativeImage)({
  width: '10ch',
  height: '10ch',
  borderRadius: '50%',
  objectFit: 'cover',
  objectPosition: 'center'
})

const ShareIconWrapper = styled('div')({
  paddingTop: '0.25rem'
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.page
 * @param {string} props.name
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.photoURL
 * @param {string} props.backgroundURL
 * //
 * @param {string} props.profileImageURL - Deprecated, use photoURL instead
 * @param {string} props.backgroundImageURL - Deprecated, use backgroundURL instead
 * @returns {JSX.Element}
 */
export default function CatalogPageProfileSection({
  page,
  name,
  title,
  description,
  photoURL,
  backgroundURL,
  // kept for backward compatibility with old page data
  // which use separate profileSection within page, and not using
  // user profile data for profile section
  profileImageURL,
  backgroundImageURL
}) {
  const { setIsShareDialogOpen } = useContext(CatalogPageContext)

  const handleOpenShareDialog = () => {
    setIsShareDialogOpen(true)
  }

  return (
    <div>
      <div>
        <BackgroundImage
          src={
            backgroundURL ?? backgroundImageURL ?? fallbackImage.grey300Pixel
          }
          alt={`${name} profile background`}
        />
      </div>

      <Box sx={{ position: 'relative', paddingX: 3, marginTop: -6 }}>
        <ProfileImageWrapper>
          <ProfileImage
            src={photoURL ?? profileImageURL ?? fallbackImage.grey400Pixel}
            alt={`${name} profile image`}
          />
        </ProfileImageWrapper>

        <Card>
          <CardContent sx={{ paddingTop: 2 }}>
            <Stack sx={{ alignItems: 'flex-end' }}>
              <Box sx={{ textAlign: 'center' }}>
                <ShareIconWrapper>
                  <IconButton onClick={handleOpenShareDialog}>
                    <Share />
                  </IconButton>
                </ShareIconWrapper>
              </Box>
            </Stack>

            <Stack spacing={1}>
              <div>
                <Typography variant='hx'>{name}</Typography>

                <Typography
                  sx={{ fontWeight: 'medium', color: 'text.secondary' }}
                >
                  {title}
                  {page.isShowViewCount && (
                    <Typography
                      component='span'
                      sx={{
                        fontWeight: 'light',
                        color: 'text.secondary'
                      }}
                    >
                      {' '}
                      &bull;{' '}
                      {formatOrFallback(
                        page.viewCount,
                        formatSimpleNumber,
                        0
                      )}{' '}
                      Visit
                    </Typography>
                  )}
                </Typography>
              </div>

              <div>
                <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
              </div>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}
