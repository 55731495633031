/**
 * @param {string} youtubeURL
 * @returns {string|null}
 */
export function getYouTubeVideoId(youtubeURL) {
  if (!youtubeURL) return null

  try {
    if (youtubeURL.includes('youtu.be')) {
      const regex = /https?:\/\/youtu.be\/(.+)/g

      let pathAfterDomain = regex.exec(youtubeURL)[1]
      if (!pathAfterDomain) return null

      // handle additional subpath, video id is always the first path
      pathAfterDomain = pathAfterDomain.split('/')[0]

      // handle query string or anchor link (hash)
      pathAfterDomain = pathAfterDomain.split(/[#?]/)[0]

      if (!pathAfterDomain) return null

      return pathAfterDomain
    } else {
      const appliedURL = youtubeURL.startsWith('http')
        ? youtubeURL
        : `https://${youtubeURL}`
      const parsedURL = new URL(appliedURL)

      return parsedURL.searchParams.get('v')
    }
  } catch (_) {
    return null
  }
}
