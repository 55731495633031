const grey200Pixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2P48u3HfwAJpgPiCX8kswAAAABJRU5ErkJggg=='
const grey300Pixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2O4//jFfwAI+QOqYhUtaAAAAABJRU5ErkJggg=='
const grey400Pixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2M4cvbqfwAIJQNmKTTa+QAAAABJRU5ErkJggg=='

const fallbackImage = {
  grey200Pixel,
  grey300Pixel,
  grey400Pixel
}

export default fallbackImage
