// react
import { useContext } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@mui/material'
import { CopyAllOutlined, LinkOutlined } from '@mui/icons-material'
// component
import NextImage from '~/components/NextImage'
// hooks
import { useSnackbar } from 'notistack'
// config
import { FE_HOSTNAME } from '~/config'

// page specific
import { CatalogPageContext } from '../context'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// social icon
import FacebookIcon from '~/assets/social-icons/facebook.png'
import WhatsAppIcon from '~/assets/social-icons/whatsapp.png'
import LinkedinIcon from '~/assets/social-icons/linkedin.png'
import TwitterIcon from '~/assets/social-icons/twitter.png'

// ----------------------------------------------------------------------

const shareButtons = [
  {
    name: 'Facebook',
    icon: FacebookIcon,
    URL: (URL) =>
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(URL)}`
  },
  {
    name: 'LinkedIn',
    icon: LinkedinIcon,
    URL: (URL) =>
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        URL
      )}`
  },
  {
    name: 'Twitter',
    icon: TwitterIcon,
    URL: (URL) =>
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(URL)}`
  },
  {
    name: 'WhatsApp',
    icon: WhatsAppIcon,
    URL: (URL) => `https://wa.me/?text=${encodeURIComponent(URL)}`
  }
]

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function ShareModal() {
  const { asPath } = useRouter()
  const { enqueueSnackbar } = useSnackbar()

  // i18n
  const { _ } = useLingui()

  const { isShareDialogOpen, setIsShareDialogOpen } =
    useContext(CatalogPageContext)

  const handleCloseShareModal = () => {
    setIsShareDialogOpen(false)
  }

  const handleCopyLink = async () => {
    if (typeof document === 'undefined') return

    await navigator.clipboard.writeText(`https://${FE_HOSTNAME}${asPath}`)

    enqueueSnackbar(_(msg`Link Clicky berhasil di salin!`))
  }

  return (
    <Dialog
      open={isShareDialogOpen}
      onClose={handleCloseShareModal}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle>Share This Page</DialogTitle>

      <DialogContent>
        <List>
          {shareButtons.map(({ name, icon, URL }) => (
            <ListItem
              key={`share-button-${URL}`}
              disablePadding
            >
              <ListItemButton
                LinkComponent='a'
                href={URL(`${FE_HOSTNAME}${asPath}`)}
                target='_blank'
              >
                <ListItemIcon>
                  <NextImage
                    src={icon}
                    alt={name}
                    nextWidth={24}
                    nextHeight={24}
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      objectFit: 'contain',
                      objectPosition: 'center'
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={`Share to ${name}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Box sx={{ marginTop: 1 }}>
          <Typography variant='shy'>{_(msg`Bagikan URL`)}</Typography>
          <TextField
            size='small'
            fullWidth
            readOnly
            value={`https://${FE_HOSTNAME}${asPath}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <LinkOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onClick={handleCopyLink}
                  >
                    <CopyAllOutlined />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          fullWidth
          size='small'
          variant='outlined'
          onClick={handleCloseShareModal}
        >
          {_(msg`Tutup`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
