import BehanceIcon from '~/assets/social-icons/behance.png'
import DiscordIcon from '~/assets/social-icons/discord.png'
import DribbleIcon from '~/assets/social-icons/dribble.png'
import FacebookIcon from '~/assets/social-icons/facebook.png'
import GithubIcon from '~/assets/social-icons/github.png'
import InstagramIcon from '~/assets/social-icons/instagram.png'
import LineIcon from '~/assets/social-icons/line.png'
import LinkedinIcon from '~/assets/social-icons/linkedin.png'
import MediumIcon from '~/assets/social-icons/medium.png'
import NotionIcon from '~/assets/social-icons/notion.png'
import PinterestIcon from '~/assets/social-icons/pinterest.png'
import SpotifyIcon from '~/assets/social-icons/spotify.png'
import TelegramIcon from '~/assets/social-icons/telegram.png'
import ThreadsIcon from '~/assets/social-icons/threads.png'
import TiktokIcon from '~/assets/social-icons/tiktok.png'
import TwitterIcon from '~/assets/social-icons/twitter.png'
import WhatsAppIcon from '~/assets/social-icons/whatsapp.png'
import YouTubeIcon from '~/assets/social-icons/youtube.png'

const socialMediaIcon = {
  behance: { label: 'Behance', icon: BehanceIcon },
  discord: { label: 'Discord', icon: DiscordIcon },
  dribble: { label: 'Dribble', icon: DribbleIcon },
  facebook: { label: 'Facebook', icon: FacebookIcon },
  github: { label: 'GitHub', icon: GithubIcon },
  instagram: { label: 'Instagram', icon: InstagramIcon },
  line: { label: 'Line', icon: LineIcon },
  linkedin: { label: 'Linkedin', icon: LinkedinIcon },
  medium: { label: 'Medium', icon: MediumIcon },
  notion: { label: 'Notion', icon: NotionIcon },
  pinterest: { label: 'Pinterest', icon: PinterestIcon },
  spotify: { label: 'Spotify', icon: SpotifyIcon },
  telegram: { label: 'Telegram', icon: TelegramIcon },
  threads: { label: 'Threads', icon: ThreadsIcon },
  tiktok: { label: 'Tiktok', icon: TiktokIcon },
  twitter: { label: 'Twitter', icon: TwitterIcon },
  whatsapp: { label: 'WhatsApp', icon: WhatsAppIcon },
  youtube: { label: 'YouTube', icon: YouTubeIcon }
}

export default socialMediaIcon
