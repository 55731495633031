// @mui
import { Card, CardContent, Grid } from '@mui/material'
// image
import NextImage from '~/components/NextImage'
// libs
import socialMediaIcon from '~/libs/socialMediaIcon'

// subcomponents
import { CatalogPageSection } from '../components'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {array} props.contents
 * @param {string} props.contents[].icon
 * @param {string} props.contents[].linkURL
 * @returns {JSX.Element}
 */
export default function CatalogPageSocialMedia({ title, contents }) {
  return (
    <CatalogPageSection title={title}>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'center' }}
      >
        {contents.map(({ icon, linkURL }) => (
          <Grid
            item
            key={`social-media-${icon}-to-${linkURL}`}
          >
            <Card variant='outlined'>
              <CardContent
                sx={{ padding: '0.75rem', paddingBottom: '0.75rem !important' }}
              >
                <a
                  href={linkURL}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <NextImage
                    src={socialMediaIcon[icon].icon}
                    alt={socialMediaIcon[icon].label}
                    nextWidth={32}
                    nextHeight={32}
                    sx={{
                      width: '2rem',
                      height: '2rem',
                      objectFit: 'contain',
                      objectPosition: 'center'
                    }}
                  />
                </a>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </CatalogPageSection>
  )
}
