// next
import NextLink from 'next/link'
// @mui
import { Box, Card, CardActionArea, CardContent, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
import NativeImage from '~/components/NativeImage'
import TextMaxLine from '~/components/TextMaxLine'
// utils
import fallbackImage from '~/libs/fallbackImage'
// paths
import { PATH_PAGE } from '~/routes/paths'

// component specific
import { CatalogPageSection } from '../components'

// ----------------------------------------------------------------------

const ListButtonImage = styled(NativeImage)(({ theme }) => ({
  height: '4rem',
  width: '4rem',
  borderRadius: theme.spacing(1),
  objectFit: 'cover',
  objectPosition: 'center'
}))

const ListButtonTitle = styled('p')(() => ({
  fontWeight: 'bold',
  lineHeight: 1
}))

const ListButtonDescription = styled(TextMaxLine)(() => ({
  marginTop: '0.25rem',
  fontSize: 'small',
  lineHeight: 1.25
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {string} props.title
 * @param {Object[]} props.contents
 * @param {string} props.contents[].title
 * @param {string} props.contents[].description
 * @param {string} props.contents[].linkURL
 * @param {string} props.contents[].imageURL
 * @param {string} props.contents[].action
 * @returns {JSX.Element}
 */
export default function CatalogPageListButton({ library, title, contents }) {
  return (
    <CatalogPageSection title={title}>
      <Stack spacing={2}>
        {contents.map(
          (
            { title: contentTitle, description, linkURL, imageURL, action },
            buttonNo
          ) => (
            <Card key={`link-${buttonNo}`}>
              <CardActionArea
                component={linkURL ? NextLink : 'div'}
                href={
                  linkURL
                    ? PATH_PAGE.out(linkURL, action, library?._id)
                    : undefined
                }
                target='_blank'
              >
                <CardContent sx={{ padding: 2 }}>
                  <Stack
                    direction='row'
                    spacing={1.5}
                    sx={{ flexWrap: 'nowrap', alignItems: 'center' }}
                  >
                    <Box sx={{ flexShrink: 0 }}>
                      <ListButtonImage
                        src={imageURL ?? fallbackImage.grey300Pixel}
                        alt={`image for ${contentTitle} button`}
                      />
                    </Box>

                    <div>
                      <ListButtonTitle>{contentTitle}</ListButtonTitle>
                      {description && (
                        <ListButtonDescription line={2}>
                          {description}
                        </ListButtonDescription>
                      )}
                    </div>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          )
        )}
      </Stack>
    </CatalogPageSection>
  )
}
