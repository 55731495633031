// @mui
import { styled } from '@mui/material/styles'

// ----------------------------------------------------------------------

const SquareImageWrapper = styled('span')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  '& > *': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  }
}))

export default SquareImageWrapper
