// react
import { useMemo } from 'react'
// @mui
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { VideocamOffOutlined } from '@mui/icons-material'
// components
import { GridCarousel } from '~/components/carousel'
// config
import { FE_HOSTNAME } from '~/config'
// utils
import { getYouTubeVideoId } from '~/libs/youtube'

// subcomponents
import { CatalogPageSection } from '../components'

// ----------------------------------------------------------------------

const PlayerArea = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  overflow: 'hidden'
}))

const YoutubePlayer = styled('iframe')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  border: 0
})

const PlayerError = styled('div')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {Object[]} props.contents
 * @param {string} props.contents[].videoURL
 * @returns {JSX.Element}
 */
export default function CatalogPageYouTubeVideo({ title, contents }) {
  const videoIdList = useMemo(
    () => contents.map(({ videoURL }) => getYouTubeVideoId(videoURL)),
    [contents]
  )

  return (
    <CatalogPageSection title={title}>
      <div>
        <GridCarousel
          isEnableDotNav={contents.length > 1}
          isEnableEdgeNav={contents.length > 1}
        >
          {videoIdList.map((videoId, videoNo) => (
            <PlayerArea key={`video-no-${videoNo}`}>
              {videoId ? (
                <YoutubePlayer
                  id={`video-${videoId}`}
                  key={`video-${videoId}`}
                  type='text/html'
                  src={`https://www.youtube-nocookie.com/embed/${videoId}?enablejsapi=1&origin=${FE_HOSTNAME}`}
                  allowFullScreen
                />
              ) : (
                <PlayerError>
                  <VideocamOffOutlined fontSize='large' />
                  <Typography variant='shy'>
                    There is a problem when trying to play the video. <br />
                    (ERROR: INVALID_VIDEO_ID)
                  </Typography>
                </PlayerError>
              )}
            </PlayerArea>
          ))}
        </GridCarousel>
      </div>
    </CatalogPageSection>
  )
}
