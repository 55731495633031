/**
 * Convert soft breaks to <br> and handle long text wrapping
 * @param {Object} props
 * @param {string} [props.children]
 * @returns {JSX.Element}
 */
export default function TextWithSoftBreaks({ children }) {
  const lines = children?.trim().split(/\r?\n|\r|\n/g) ?? []

  if (lines.length === 0) {
    return null
  }

  const wrapStyle = {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto'
  }

  return lines.map((line, index) =>
    line ? (
      <p
        key={`line-${index}`}
        style={wrapStyle}
      >
        {line}
      </p>
    ) : (
      <br key={`break-${index}`} />
    )
  )
}
