// next
import NextLink from 'next/link'
// @mui
import { Box, Grid, Link, Typography } from '@mui/material'
// paths
import { PATH_PAGE } from '~/routes/paths'

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function CatalogPageFooter() {
  return (
    <Box
      component='footer'
      sx={{ padding: 2, paddingBottom: 1 }}
    >
      <Grid
        container
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          item
          xs='auto'
        >
          <Typography sx={{ fontSize: 'small', color: 'text.secondary' }}>
            Made with ❤️ using{' '}
            <Link
              component={NextLink}
              href={PATH_PAGE.landing}
            >
              Clicky.id
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
