// @mui
import { Box, Stack } from '@mui/material'

// page specific
import { CatalogPageContextProvider } from './context'
import {
  CatalogPageGridImage,
  CatalogPageListButton,
  CatalogPagePlainText,
  CatalogPageProfileSection,
  CatalogPageSlideImage,
  CatalogPageSocialMedia,
  CatalogPageYouTubeVideo
} from './section'
import { CatalogPageShareDialog } from './components'

// ----------------------------------------------------------------------

const sectionComponent = Object.freeze({
  'list-button': {
    label: 'Button Link',
    sectionComponent: CatalogPageListButton
  },
  'grid-image': {
    label: 'Grid Image',
    sectionComponent: CatalogPageGridImage
  },
  'plain-text': {
    label: 'Text',
    sectionComponent: CatalogPagePlainText
  },
  'slide-image': {
    label: 'Swipe Images',
    sectionComponent: CatalogPageSlideImage
  },
  'social-media': {
    label: 'Social Media',
    sectionComponent: CatalogPageSocialMedia
  },
  'youtube-video': {
    label: 'YouTube Video',
    sectionComponent: CatalogPageYouTubeVideo
  }
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {Object} props.page
 * @param {Object} props.profileSection
 * @returns {JSX.Element}
 */
export default function CatalogPage({ library, page, profileSection }) {
  const { sections } = page ?? {}

  return (
    <CatalogPageContextProvider>
      <Box
        sx={{
          height: 'auto',
          minHeight: '100vh',
          backgroundColor: 'pageBackground'
        }}
      >
        <Box>
          <Stack
            spacing={3}
            sx={{ minHeight: '100vh', paddingBottom: 4 }}
          >
            <CatalogPageProfileSection
              library={library}
              page={page}
              {...profileSection}
            />

            {sections.map(({ type, props }, sectionNo) => {
              const Section = sectionComponent[type].sectionComponent

              // if section is not recognized, skip it
              if (!Section) {
                console.warn(
                  `section #${sectionNo} is not shown because of section type "${type}" is not recognized`
                )

                return null
              }

              return (
                <Section
                  key={`section-${sectionNo}`}
                  library={library}
                  page={page}
                  {...props}
                />
              )
            })}
          </Stack>
        </Box>
      </Box>

      <CatalogPageShareDialog />
    </CatalogPageContextProvider>
  )
}
