// @mui
import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
import NativeImage from '~/components/NativeImage'
import ImageLinkOverlay from '~/components/ImageLinkOverlay'
import { GridCarousel } from '~/components/carousel'
// utils
import fallbackImage from '~/libs/fallbackImage'

// subcomponents
import { CatalogPageSection } from '../components'

// ----------------------------------------------------------------------

const Image = styled(NativeImage)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.spacing(2)
}))

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {array} props.contents
 * @param {string} props.contents[].imageURL
 * @param {string} props.contents[].linkURL
 * @returns {JSX.Element}
 */
export default function CatalogPageSlideImage({ title, contents }) {
  return (
    <CatalogPageSection title={title}>
      <GridCarousel
        isPersistEdgeNavOnMobile
        isEnableDotNav={contents.length > 1}
        isEnableEdgeNav={contents.length > 1}
      >
        {contents.map(({ imageURL, linkURL }) =>
          linkURL ? (
            <Card
              key={`image-from-${imageURL}`}
              variant='outlined'
            >
              <ImageLinkOverlay
                LinkComponent='a'
                href={linkURL}
                tagret='_blank'
              >
                <Image
                  src={imageURL ?? fallbackImage.grey300Pixel}
                  alt={`Image for ${title}`}
                />
              </ImageLinkOverlay>
            </Card>
          ) : (
            <Card
              key={`image-from-${imageURL}`}
              variant='outlined'
            >
              <Image
                src={imageURL ?? fallbackImage.grey300Pixel}
                alt={`Image for ${title}`}
              />
            </Card>
          )
        )}
      </GridCarousel>
    </CatalogPageSection>
  )
}
