// react
import { createContext, useReducer } from 'react'

// ----------------------------------------------------------------------

const initialState = {
  isShareDialogOpen: false,
  setIsShareDialogOpen: () => null
}

const reducer = (state, action) => {
  if (action.type === 'SET_IS_SHARE_DIALOG_OPEN') {
    const { isShareDialogOpen } = action.payload

    return { ...state, isShareDialogOpen }
  }

  return state
}

const CatalogPageContext = createContext({
  ...initialState
})

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
function CatalogPageContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setIsShareDialogOpen = (data) => {
    dispatch({
      type: 'SET_IS_SHARE_DIALOG_OPEN',
      payload: { isShareDialogOpen: data }
    })
  }

  return (
    <CatalogPageContext.Provider value={{ ...state, setIsShareDialogOpen }}>
      {children}
    </CatalogPageContext.Provider>
  )
}

export { CatalogPageContext, CatalogPageContextProvider }
