// react
// @mui
import { Card, CardContent } from '@mui/material'
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'

// component specific
import { CatalogPageSection } from '../components'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {Object} props.content
 * @param {string} props.content.alignment
 * @param {string} props.content.text
 * @returns {JSX.Element}
 */
export default function CatalogPagePlainText({ title, content }) {
  return (
    <CatalogPageSection
      title={title}
      alignTitle={content.alignment}
    >
      <Card>
        <CardContent sx={{ textAlign: content.alignment }}>
          <TextWithSoftBreaks>{content.text}</TextWithSoftBreaks>
        </CardContent>
      </Card>
    </CatalogPageSection>
  )
}
